import { Box, Typography } from "@material-ui/core";
import theme, { fontWeightRegular } from "../../../layouts/theme";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import RVOAnimateFade from "../../01-atoms/animations/RVOAnimateFade/RVOAnimateFade";

export interface RVOFeatureGridCardCenteredProps {
  /*
   *
   */
  children?: React.ReactNode;
  title: string;
  text: string;
  imgUrl?: string;
}

const RVOFeatureGridCardCentered = ({
  children,
  title,
  text,
  imgUrl,
}: RVOFeatureGridCardCenteredProps): JSX.Element => {
  return (
    <Box
      style={{
        backgroundColor: "transparent",
        borderRadius: theme.shape.borderRadius,
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
      >
        <Box
          padding="2rem"
          display="flex"
          flexDirection="column"
          alignItems={"center"}
          style={{ gap: theme.spacing(2) }}
        >
          {imgUrl && (
            <Box style={{ width: "70%" }}>
              <RVOAnimateFade delay={0.3}>
                <img
                  src={imgUrl}
                  width="100%"
                  height="auto"
                  style={{
                    display: "block",
                  }}
                />
              </RVOAnimateFade>
            </Box>
          )}

          <Typography style={{ textAlign: "center", margin: 0 }} variant="h4">
            {title}
          </Typography>

          <Typography
            variant="h6"
            style={{
              textAlign: "center",
              fontWeight: fontWeightRegular,
              margin: 0,
            }}
          >
            <HtmlFromLangFile contentContainingHtml={text} />
          </Typography>
        </Box>

        {children}
      </Box>
    </Box>
  );
};

export default RVOFeatureGridCardCentered;
