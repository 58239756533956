import { Typography } from "@material-ui/core";
import { clsx } from "clsx";
import { useMemo } from "react";
import { cleanupWpHeadlines } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/mappings/htmlTagsMappedToReactComponents";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { VTSUnderlinedTitleProps } from "./VTSUnderlinedTitleInterfaces";
import { useVTSUnderlinedTitleStyles } from "./VTSUnderlinedTitleStyles";

const VTSUnderlinedTitle = (props: VTSUnderlinedTitleProps): JSX.Element => {
  const classes = useVTSUnderlinedTitleStyles();

  const underlinedTitle = useMemo(() => {
    return (
      props.titleRaw &&
      replaceHtmlWithFunctionComponent(props.titleRaw, cleanupWpHeadlines)
    );
  }, [props.titleRaw]);

  return (
    <Typography
      {...props.typographyProps}
      component={props.component || "span"}
      className={clsx(props.typographyProps.className, classes.wrapper)}
    >
      {underlinedTitle}
    </Typography>
  );
};

export default VTSUnderlinedTitle;
