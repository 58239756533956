import { createStyles, makeStyles } from "@material-ui/core";

export const useVTSUnderlinedTitleStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      "& > p": {
        margin: 0,
      },
    },
  })
);
