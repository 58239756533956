import { Typography } from "@material-ui/core";
import { clsx } from "clsx";
import RVOAnimateFade from "../../01-atoms/animations/RVOAnimateFade/RVOAnimateFade";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import {
  BackgroundColors,
  RVOFeaturedTextProps,
} from "./RVOFeaturedTextInterfaces";
import { useRVOFeaturedTextStyles } from "./RVOFeaturedTextStyles";

const RVOFeaturedTextSection = (props: RVOFeaturedTextProps): JSX.Element => {
  const classes = useRVOFeaturedTextStyles();

  return (
    <div
      className={clsx(
        classes.wrapper,
        props.xxlHeadline && classes.xxlHeadline,
        props.wide ? classes.wide : classes.nowide,
        props.headlineCentered
          ? classes.headlineCentered
          : classes.headlineLeft,
        props.backgroundColored ? classes[props.backgroundColored] : undefined
      )}
    >
      <RVOContainer paddingBottom={true}>
        <Typography
          component={props.pretextComponent || "h6"}
          variant="subtitle1"
          className={clsx(
            props.backgroundColored === BackgroundColors.GoldDark ||
              props.backgroundColored === BackgroundColors.GoldLight
              ? classes.textColorBlue
              : classes.textColorGold,
            props.headlineCentered
              ? classes.headlineCentered
              : classes.headlineLeft
          )}
          style={{ fontWeight: 800 }}
        >
          {props.pretext}
        </Typography>

        <RVOAnimateFade>
          {props.backgroundColored === BackgroundColors.GoldDark ||
          props.backgroundColored === BackgroundColors.BlueDark ? (
            <Typography
              variant={props.headlineVariant || "h1"}
              component={props.headlineComponent || "h2"}
              className={classes.textColorWhite}
            >
              {props.headline}
            </Typography>
          ) : (
            <VTSUnderlinedTitle
              titleRaw={props.headline}
              typographyProps={{
                variant: props.headlineVariant || "h1",
                className: clsx(
                  props.boldHeadline ? classes.boldHeadline : undefined,
                  props.noSerif
                    ? classes.fontFamilyNoSerif
                    : classes.fontFamilySerif
                ),
              }}
              component={props.headlineComponent || "h2"}
            />
          )}
        </RVOAnimateFade>

        {props.children}
      </RVOContainer>
    </div>
  );
};

export default RVOFeaturedTextSection;
