/* eslint-disable i18next/no-literal-string */
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DescriptionTwoTone, PlayArrowTwoTone } from "@material-ui/icons";
import { assertTruthy } from "@nvon/baseline";
import { useSmoothWindowScroll } from "@nvon/react-toolbox";
import { Button } from "gatsby-material-ui-components";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { usePosthogEvent } from "../../../hooks/useTrackingEvent";
import { RVOTrackingEvent } from "../../00-globals/TruendoGoogleTagManager/trackEvent";
import FeaturedVideo from "../../01-atoms/FeaturedVideo/FeaturedVideo";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import RVOTrustLogos from "../../01-atoms/RVOHeroTrustLogos/RVOHeroTrustLogos";
import RVOAnimateFade from "../../01-atoms/animations/RVOAnimateFade/RVOAnimateFade";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import RVOFeatureGridCardCentered from "../../02-molecules/RVOFeatureGridCard/RVOFeatureGridCardCentered";
import {
  BackgroundColors,
  HeadlineComponent,
} from "../../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextInterfaces";
import RVOFeaturedTextSection from "../../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextSection";
import RVOPeopleTrustResolvio from "../../03-organisms/RVOPeopleTrustResolvio/RVOPeopleTrustResolvio";
import RVODemoCallButtonGroup from "../RVODemoCallButtonGroup/RVODemoCallButtonGroup";

interface StepsSectionStep {
  title: string;
  description: string;
}

interface Benefit {
  title: string;
  description: string;
  illustration: string;
}

interface BenefitSection {
  pretitle: string;
  title: string;
  benefits: Benefit[];
}

interface StepsSection {
  title: string;
  illustration: string;
  steps: StepsSectionStep[];
}

interface PageInformation {
  title: string;
  pretitle: string;
  subtitle: string;
}

interface VideoSection {
  title: string;
  description: string;
  schemaUploadDate: string;
  url: string;
  thumbnail: string;
}

interface TemplateSection {
  pretitle: string;
  title: string;
  description: string;
  listIntro: string;
  templates: { name: string }[];
}

interface RVOTargetGroupTemplateProps {
  pageInformation: PageInformation;
  benefitsSection: BenefitSection;
  stepsSection: StepsSection;
  videoSection?: VideoSection;
  templateSection?: TemplateSection;
}

const RVOTargetGroupTemplate = ({
  pageInformation,
  benefitsSection,
  stepsSection,
  videoSection: video,
  templateSection,
}: RVOTargetGroupTemplateProps): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const sanitzedTitle = pageInformation.title.replace(/<\/?[^>]+(>|$)/g, "");
  const scrollTo = useSmoothWindowScroll();
  const scrollToSection = (sectionId: string) => {
    const element = document.querySelector(`#${sectionId}`);
    assertTruthy(element, `Did not find section with id ${sectionId}`);

    scrollTo(element);
  };

  const { firePosthogEvent } = usePosthogEvent();
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{sanitzedTitle}</title>

        <meta name="description" content={pageInformation.subtitle} />
      </Helmet>

      <RVOFeaturedTextSection
        pretext={pageInformation.pretitle}
        headlineCentered={true}
        wide={false}
        pretextComponent={HeadlineComponent.H1}
        xxlHeadline={true}
        headline={pageInformation.title}
      >
        <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
          {pageInformation.subtitle}
        </Typography>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(4),
            marginTop: theme.spacing(4),
            alignItems: "center",
          }}
        >
          <RVODemoCallButtonGroup />

          {video && (
            <Button
              href={`#video`}
              variant="outlined"
              startIcon={<PlayArrowTwoTone />}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                scrollToSection(`video`);
                firePosthogEvent({
                  eventId: RVOTrackingEvent.WatchDemoVideoClicked,
                });
                // Prevent flash before scrolling
                event.preventDefault();
              }}
            >
              {t("home.hero.buttons.watchVideo")}
            </Button>
          )}

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
              alignItems: "center",
            }}
          >
            <RVOPeopleTrustResolvio />

            <RVOTrustLogos />
          </Box>
        </Box>
      </RVOFeaturedTextSection>

      <RVOFeaturedTextSection
        pretext={benefitsSection.pretitle}
        backgroundColored={BackgroundColors.BlueLight}
        headlineCentered={true}
        wide={true}
        xxlHeadline={false}
        headline={benefitsSection.title}
      >
        <Grid container={true} spacing={2}>
          {benefitsSection.benefits.map((benefit) => {
            return (
              <Grid
                key={benefit.title}
                item={true}
                xs={12}
                sm={12}
                md={4}
                style={{ flex: 1 }}
              >
                <RVOFeatureGridCardCentered
                  title={benefit.title}
                  text={benefit.description}
                  imgUrl={benefit.illustration}
                />
              </Grid>
            );
          })}
        </Grid>
      </RVOFeaturedTextSection>

      {video && (
        <RVOContainer paddingBottom={false} id={"video"}>
          <Box marginBottom={2} justifyContent="center" display="flex">
            <Typography variant="h3">{video.title}</Typography>
          </Box>

          <Box marginBottom={2} justifyContent="center" display="flex">
            <Typography variant="body1">{video.description}</Typography>
          </Box>

          <FeaturedVideo
            schemaTitle={video.title}
            schemaDescription={video.description}
            schemaUploadDate={video.schemaUploadDate}
            schemaContentUrl={video.url}
            schemaThumbnailUrl={video.thumbnail}
            schemaRegionsAllowed="DE, AT, CH"
            videoUrl={video.url}
            posterImage={video.thumbnail}
            minimizeOnlyWhilePlaying={true}
            playing={false}
          />
        </RVOContainer>
      )}

      {templateSection && (
        <RVOFeaturedTextSection
          pretext={templateSection.pretitle}
          backgroundColored={BackgroundColors.BlueLight}
          headlineCentered={true}
          wide={false}
          xxlHeadline={false}
          headline={templateSection.title}
        >
          <Box marginBottom={2} justifyContent="center" display="flex">
            <Typography
              variant="body1"
              style={{ color: theme.palette.primary.main }}
            >
              {templateSection.description}
            </Typography>
          </Box>

          <Box marginBottom={2} justifyContent="center" display="flex">
            <Typography
              variant="body1"
              style={{ color: theme.palette.primary.main }}
            >
              {templateSection.listIntro}
            </Typography>
          </Box>

          <List>
            {templateSection.templates.map((template) => {
              return (
                <ListItem button={false} key={template.name}>
                  <ListItemIcon style={{ flexShrink: 1 }}>
                    <DescriptionTwoTone />

                    <ListItemText
                      primary={template.name}
                      style={{
                        marginLeft: theme.spacing(1),
                      }}
                      primaryTypographyProps={{ variant: "h5" }}
                    />
                  </ListItemIcon>
                </ListItem>
              );
            })}

            <ListItem button={false}>
              <ListItemIcon style={{ flexShrink: 1 }}>
                <DescriptionTwoTone />

                <ListItemText
                  primary={t("general.andManyMore")}
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                  primaryTypographyProps={{ variant: "h5" }}
                />
              </ListItemIcon>
            </ListItem>
          </List>
        </RVOFeaturedTextSection>
      )}

      <RVOFeaturedTextSection
        headlineCentered={false}
        wide={false}
        xxlHeadline={false}
        headline={stepsSection.title}
      >
        <Grid container={true} spacing={2} style={{ width: "100%" }}>
          <Grid item={true} xs={12} sm={12} md={8}>
            <Stepper orientation="vertical">
              {stepsSection.steps.map((step) => (
                <Step active={true} key={step.title}>
                  <StepLabel>
                    <Typography
                      variant="h4"
                      style={{ marginLeft: theme.spacing(1), marginBottom: 0 }}
                    >
                      {step.title}
                    </Typography>
                  </StepLabel>

                  <StepContent>
                    <Typography
                      style={{
                        marginLeft: theme.spacing(1),
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <HtmlFromLangFile
                        contentContainingHtml={step.description}
                      />
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>

          <Grid
            item={true}
            xs={12}
            sm={2}
            md={4}
            style={{ display: isSmallScreen ? "none" : "block" }}
          >
            <RVOAnimateFade delay={0.3}>
              <img
                src={stepsSection.illustration}
                width="100%"
                height="auto"
                style={{
                  display: "block",
                }}
              />
            </RVOAnimateFade>
          </Grid>
        </Grid>

        <RVODemoCallButtonGroup />
      </RVOFeaturedTextSection>
    </>
  );
};

export default RVOTargetGroupTemplate;
