import { ReactNode } from "react";

export enum BackgroundColors {
  BlueLight = "BlueLight",
  BlueDark = "BlueDark",
  GoldLight = "GoldLight",
  GoldDark = "GoldDark",
}

export interface RVOFeaturedTextProps {
  pretext?: string;
  pretextColor?: string;
  headline?: string;
  xxlHeadline?: boolean;
  pretextComponent?: HeadlineComponent;
  headlineComponent?: HeadlineComponent;
  headlineVariant?: HeadlineVariant;
  headlineCentered?: boolean;
  wide?: boolean;
  backgroundColored?: Partial<BackgroundColors>;
  children?: ReactNode;
  boldHeadline?: boolean;
  noSerif?: boolean;
}

export enum HeadlineComponent {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
}

export enum HeadlineVariant {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
}
