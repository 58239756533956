import { useTranslation } from "react-i18next";
import RVOPartnerUpWithUsTemplate from "../04-templates/RVOTargetGroupTemplate/RVOTargetGroupTemplate";

export const FuerWegsOesterreichPage = (): JSX.Element => {
  const { t } = useTranslation();
  const pageInformation = {
    title: t("fuerWegsOesterreich.title"),
    pretitle: t("fuerWegsOesterreich.pretitle"),
    subtitle: t("fuerWegsOesterreich.subtitle"),
  };
  const benefitsSection = {
    pretitle: t("fuerWegsOesterreich.benefits.pretitle"),
    title: t("fuerWegsOesterreich.benefits.title"),
    benefits: [
      {
        title: t("fuerWegsOesterreich.benefits.benefit1.title"),
        description: t("fuerWegsOesterreich.benefits.benefit1.description"),
        illustration: "/app/illustrations/task-management.svg",
      },
      {
        title: t("fuerWegsOesterreich.benefits.benefit2.title"),
        description: t("fuerWegsOesterreich.benefits.benefit2.description"),
        illustration: "/app/illustrations/online-meeting.svg",
      },
      {
        title: t("fuerWegsOesterreich.benefits.benefit3.title"),
        description: t("fuerWegsOesterreich.benefits.benefit3.description"),
        illustration: "/app/illustrations/online-file-sharing.svg",
      },
    ],
  };
  const stepsSection = {
    title: t("fuerWegsOesterreich.how.title"),
    illustration: "/app/illustrations/goal-setting.svg",
    steps: [
      {
        title: t("fuerWegsOesterreich.how.step1.title"),
        description: t("fuerWegsOesterreich.how.step1.description"),
      },
      {
        title: t("fuerWegsOesterreich.how.step2.title"),
        description: t("fuerWegsOesterreich.how.step2.description"),
      },
      {
        title: t("fuerWegsOesterreich.how.step3.title"),
        description: t("fuerWegsOesterreich.how.step3.description"),
      },
    ],
  };

  const templateSection = {
    pretitle: t("fuerWegsOesterreich.templateSection.pretitle"),
    title: t("fuerWegsOesterreich.templateSection.title"),
    description: t("fuerWegsOesterreich.templateSection.description"),
    listIntro: t("fuerWegsOesterreich.templateSection.listIntro"),
    templates: [
      {
        name: "Beschlussfassung zur Jahresabrechnung",
      },
      {
        name: "Jahresabrechnung und Ver⁠mögensbericht",
      },
      {
        name: "Beschlussmuster zum Wirtschaftsplan",
      },
      {
        name: "Erhebung einer Sonderumlage",
      },
      {
        name: "Bildung einer zusätzlichen Rücklage",
      },
      {
        name: "Bildung einer weiteren Rücklage durch Umwidmung",
      },
      {
        name: "Verwendung einer Rücklage (Liquiditätssicherung durch Zugriff auf die Rücklage)",
      },
      {
        name: "Bildung einer Liquiditätsrücklage",
      },
      {
        name: "Änderung der Kostenverteilung",
      },
      {
        name: "Muster einer Hausordnung",
      },
      {
        name: "Gebrauchsregelung für gemeinschaftliche Kfz-Stellplätze",
      },
      {
        name: "Beschlussvorlage zur Vermietung von Gemeinschaftseigentum",
      },
      {
        name: "Beschlussmuster zur Vorbereitung und Durchführung des Erwerbs von Grundeigentum",
      },
      {
        name: "Beschlüsse zur Digitalisierung/Papierloser Verwaltung",
      },
      {
        name: "Verwalterbestellung und Abschluss des Verwaltervertrags",
      },
      {
        name: "Erstellung eines Instandsetzungskonzepts",
      },
      {
        name: "Beschluss über eines Auftragsvergabe",
      },
      {
        name: "Instandhaltungsmaßnahmen",
      },
      {
        name: "Verkehrssicherungsmaßnahmen",
      },
      {
        name: "Instandsetzungsmaßnahmen",
      },
      {
        name: "Verfolgung von Mängelansprüchen (z.B. gegenüber dem Bauträger)",
      },
      {
        name: "Beschlussmuster zur Gestattung von baulichen Veränderungen am Sondereigentum/am Gemeinschaftseigentum",
      },
      {
        name: "Delegation der Verkehrssicherungspflicht auf Dritte",
      },
      {
        name: "Beschlussmuster zur Darlehensaufnahme durch die WEG",
      },
      {
        name: "Betriebskonzept Videoüberwachung",
      },
      {
        name: "Schadensregulierung auch betreffend Sondereigentum über die Gemeinschaft/über einzelnen Eigentümer (Einzelfall- oder Vorratsbeschluss)",
      },
      {
        name: "Anbringung von Balkonkraftwerken",
      },
      {
        name: "Zustimmung zur Errichtung einer Gemeinschaftlichen Erzeugungsanlage (GEA)",
      },
    ],
  };

  return (
    <RVOPartnerUpWithUsTemplate
      pageInformation={pageInformation}
      benefitsSection={benefitsSection}
      stepsSection={stepsSection}
      templateSection={templateSection}
    />
  );
};

export default FuerWegsOesterreichPage;
