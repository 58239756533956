/* eslint-disable i18next/no-literal-string */
import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import {
  brevoDemoUrl,
  phoneNumber,
} from "../../../helpers/clientSide/constants";
import { usePosthogEvent } from "../../../hooks/useTrackingEvent";
import { RVOTrackingEvent } from "../../00-globals/TruendoGoogleTagManager/trackEvent";
import RVOLinkButton from "../../01-atoms/RVOLinkButton/RVOLinkButton";

const RVODemoCallButtonGroup = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { firePosthogEvent } = usePosthogEvent();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        gap: theme.spacing(2),
        marginTop: theme.spacing(4),
        justifyContent: "center",
      }}
    >
      <RVOLinkButton
        buttonProps={{
          variant: "contained",
          onClick: () =>
            firePosthogEvent({
              eventId: RVOTrackingEvent.BookDemoClicked,
            }),
        }}
        href={brevoDemoUrl}
      >
        {t("targetGroupPage.bookDemo")}
      </RVOLinkButton>

      <Button
        href={`tel:${phoneNumber}`}
        variant="outlined"
        startIcon={<Phone />}
        onClick={() =>
          firePosthogEvent({
            eventId: RVOTrackingEvent.CallResolvioClicked,
          })
        }
      >
        {phoneNumber}
      </Button>
    </Box>
  );
};

export default RVODemoCallButtonGroup;
