import { createStyles, makeStyles } from "@material-ui/core";
import { fontWeightBold } from "../../../layouts/theme";
import { BackgroundColors } from "./RVOFeaturedTextInterfaces";

export const useRVOFeaturedTextStyles = makeStyles((theme) =>
  createStyles({
    headlineCentered: {
      textAlign: "center",
    },
    headlineLeft: {
      textAlign: "left",
    },
    xxlHeadline: {
      "& .MuiTypography-h1": {
        marginBottom: "3rem",
        fontSize: "6rem !important",
        [theme.breakpoints.down("md")]: {
          fontSize: "4rem !important",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "3rem !important",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "2rem !important",
        },
      },
    },
    boldHeadline: {
      fontWeight: fontWeightBold,
    },
    roadmapDescription: {
      textAlign: "center",
    },

    [BackgroundColors.BlueLight]: {
      backgroundColor: theme.palette.secondary.light,
    },
    [BackgroundColors.BlueDark]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [BackgroundColors.GoldLight]: {
      backgroundColor: theme.palette.warning.light,
    },
    [BackgroundColors.GoldDark]: {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.common.white,
    },
    wide: {
      width: "100%",
      textAlign: "center",
    },
    nowide: {
      padding: `0 ${theme.spacing(10)}`,
      [theme.breakpoints.only("md")]: {
        padding: `0 ${theme.spacing(8)}`,
      },
      [theme.breakpoints.only("sm")]: {
        padding: `0 ${theme.spacing(6)}`,
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: theme.spacing(3),
        padding: 0,
      },
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
    },
    textColorGold: {
      color: `${theme.palette.warning.main} !important`,
    },
    textColorBlue: {
      color: `${theme.palette.primary.dark} !important`,
    },
    textColorWhite: {
      color: `${theme.palette.common.white} !important`,
    },
    fontFamilySerif: {
      fontFamily: theme.typography.h1.fontFamily,
    },
    fontFamilyNoSerif: { fontFamily: theme.typography.body1.fontFamily },
  })
);
